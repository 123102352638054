import { Typography } from "@material-ui/core"

import Users from "src/components/Users"

const MagasinPage = () => {
  return (
    <>
      <Typography variant="h2">Groupe MAG</Typography>
      <Users />
    </>
  )
}

export default MagasinPage
