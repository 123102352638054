import { Typography } from "@material-ui/core"

import Users from "src/components/Users"

const BdMPage = () => {
  return (
    <>
      <Typography variant="h2">Groupe BdM</Typography>
      <Users />
    </>
  )
}

export default BdMPage
